import styled from "styled-components";

export const NotActiveOverlay = styled.div`
    background: #f9f9f9;
    cursor: not-allowed;
    height: 100vh;
    left: 0;
    opacity: 0.7;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9998;
`;

export const NotActiveMessage = styled.div`
    background: #fff;
    border-radius: 24px;
    height: 320px;
    left: calc(50% - 160px);
    padding: 32px;
    position: fixed;
    text-align: center;
    top: calc(50% - 160px);
    width: 320px;
    z-index: 9999;

    strong {
        color: var(--color-secondary);
        display: block;
        font-family: var(--font-primary-bold);
        font-size: 16px;
        margin-bottom: 16px;
    }
`;
