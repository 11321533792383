import { useRouter } from "next/router";
import Image from "next/image";
import React, { useState } from "react";

import { StyledContainer } from "components/form/partials/dateSelector/DateSelector.styled";
import {
    StyledImage,
    StyledOption,
    StyledSpacer
} from "components/form/partials/campSelector/CampSelector.styled";
import InputLabel from "components/shared/inputLabel/InputLabel";

type Props = {
    onChange: (value: string) => void;
};

export function CampSelector({ onChange }: Props): JSX.Element {
    const router = useRouter();
    const [selectedOption, setSelectedOption] = useState("");
    const campOptions = [
        {
            icon: "/images/caravan.svg",
            image: "/images/caravan_big.svg",
            name: "Caravan",
            value: "Caravan"
        },
        {
            icon: "/images/camper.svg",
            image: "/images/camper_big.svg",
            name: "Camper",
            value: "Camper"
        },
        {
            icon: "/images/tenticon.svg",
            image: "/images/tenticon_big.svg",
            name: "Tent",
            value: "Tent"
        },
        {
            icon: "/images/caravan.svg",
            image: "/images/caravan_big.svg",
            name: "Vouwwagen",
            value: "Vouwwagen"
        },
        {
            icon: "/images/house.svg",
            image: "/images/house_big.svg",
            name: "Ik verblijf in een huuraccommodatie",
            value: "Huuraccommodatie"
        }
    ];

    function handleOnChange(value: string) {
        setSelectedOption(value);
        onChange(value);
        router.push("/#checkboxes");
    }

    return (
        <>
            <StyledSpacer />
            <InputLabel value="Met welk kampeermiddel ga je kamperen?"></InputLabel>
            <StyledContainer>
                {campOptions.map(option => (
                    <>
                        <StyledOption
                            selected={selectedOption === option.value}
                            onClick={() => handleOnChange(option.value)}
                        >
                            <Image
                                src={option.icon}
                                width={
                                    option.value === "Huuraccommodatie"
                                        ? 64
                                        : 42
                                }
                                height={
                                    option.value === "Huuraccommodatie"
                                        ? 42
                                        : 22
                                }
                                alt={option.value}
                            />
                            <span>{option.name}</span>
                        </StyledOption>
                    </>
                ))}
            </StyledContainer>
            {selectedOption === "" && (
                <StyledImage>
                    <Image
                        src="/images/caravanimage_big.svg"
                        width={312}
                        height={217}
                    />
                </StyledImage>
            )}
            {selectedOption === "Caravan" && (
                <StyledImage>
                    <Image
                        src="/images/caravanimage_big.svg"
                        width={312}
                        height={217}
                    />
                </StyledImage>
            )}
            {selectedOption === "Camper" && (
                <StyledImage>
                    <Image
                        src="/images/camper_big.svg"
                        width={312}
                        height={217}
                    />
                </StyledImage>
            )}
            {selectedOption === "Tent" && (
                <StyledImage>
                    <Image
                        src="/images/tent_big.svg"
                        width={312}
                        height={217}
                    />
                </StyledImage>
            )}
            {selectedOption === "Vouwwagen" && (
                <StyledImage>
                    <Image
                        src="/images/vouwwagen_big.svg"
                        width={312}
                        height={217}
                    />
                </StyledImage>
            )}
            {selectedOption === "Huuraccommodatie" && (
                <StyledImage>
                    <Image
                        src="/images/houseimage_big.svg"
                        width={312}
                        height={217}
                    />
                </StyledImage>
            )}
        </>
    );
}
