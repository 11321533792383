import MenuListComponentProps from "react-select";
import React, { FC } from "react";

import { DropdownOption } from "components/shared/dropdown/types";
import { StyledMenuList } from "components/shared/dropdown/Dropdown.styled";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MenuList: FC<MenuListComponentProps<DropdownOption>> = props => {
    const { children, innerRef } = props;

    return <StyledMenuList ref={innerRef}>{children}</StyledMenuList>;
};

export default MenuList;
