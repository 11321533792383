import { MultiValueProps } from "react-select";
import React, { FC } from "react";

import { DropdownOption } from "components/shared/dropdown/types";

const MultiValueLabel: FC<MultiValueProps<DropdownOption>> = ({
    data: { label, chipLabel },
    innerProps
}) => {
    return <div {...innerProps}>{chipLabel ?? label}</div>;
};

export default MultiValueLabel;
