import styled, { css } from "styled-components";

import { Icon } from "components/shared/icon";

export const StyledLoadingWrapper = styled.div`
    align-items: center;
    display: flex;
    margin: 12px 0 16px 24px;
`;

export const StyledLoadingIcon = styled(Icon)`
    animation: rotate var(--animation-long-duration) infinite linear;
    margin-right: 10px;
    position: relative;

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
`;

export const StyledLoadingMessage = styled.div`
    color: var(--color-secondary-darker);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
`;

export const StyledDropdownIndicator = styled.div<{
    isIndicatorRotated?: boolean;
}>`
    cursor: pointer;
    margin-left: 8px;
    transform: ${props =>
        props.isIndicatorRotated ? "rotate(-90deg)" : "rotate(90deg)"};
    transition: transform var(--animation-medium-exit-duration);
`;

export const StyledDropdownIndicatorIcon = styled(Icon)`
    padding-left: 5px;
`;

export const StyledControl = styled.div<{
    isFocused?: boolean;
    isDisabled?: boolean;
}>`
    background: #fff;
    border: 1px solid var(--color-primaryLight);
    border-radius: 6px;
    display: flex;
    height: 56px;
    padding: 0 16px;
    transition: border-color var(--animation-medium-exit-duration);

    &:hover {
        border-color: var(--color-primary-dark);
    }

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isFocused }) =>
        isFocused &&
        css`
            border-color: var(--color-primary);
        `} /* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            background-color: var(--color-secondary-lighter);
            border-color: var(--color-secondary-medium);
        `} /* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */
`;

export const StyledMenuList = styled.div`
    max-height: 384px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        background: #fff;
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--color-primaryLight);
        background-clip: padding-box;
        border-radius: 64px;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    &::-webkit-scrollbar-button {
        background: var(--color-primary-light);
        display: block;
        height: 8px;
    }

    div {
        font-family: var(--font-primary);
        font-weight: 400;
    }
`;

export const StyledOption = styled.div<{
    isDisabled?: boolean;
    isSelected?: boolean;
    hasIcon?: boolean;
}>`
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 12px 24px;
    transition-duration: var(--animation-medium-duration);
    transition-property: background, color;

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            color: var(--color-error);
        `} /* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ hasIcon }) =>
        hasIcon &&
        css`
            padding: 12px;
        `}/* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isSelected }) =>
        isSelected &&
        css`
            background-color: var(--color-secondary-lighter);
            color: var(--color-primary);
        `}/* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */

    &:hover {
        background: var(--color-secondary-lighter);
        color: var(--color-primary);
    }
`;
export const StyledOptionIcon = styled.span`
    align-items: center;
    border-radius: 12px;
    display: flex;
    margin-right: 16px;
    max-height: 16px;
`;

export const StyledPlaceholder = styled.div`
    color: var(--color-secondary-dark);
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 400;
`;

export const StyledSingleValue = styled.div`
    align-items: center;
    display: flex;
    font-family: var(--font-primary);
    font-weight: 400;
`;

export const StyledInput = styled.input`
    bottom: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
`;

export const StyledValueContainer = styled.div<{
    isSingleValue?: boolean;
    isDisabled?: boolean;
}>`
    align-items: center;
    display: flex;
    height: 56px;
    width: 100%;

    ${StyledInput} {
        font-family: var(--font-primary);
        font-weight: 400;
    }

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isSingleValue, isDisabled }) =>
        isSingleValue &&
        css`
            color: ${isDisabled ? "var(--color-secondary-dark)" : "initial"};
            font-family: var(--font-primary);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 1px;
        `}/* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */
`;

export const StyledDropdown = styled.div<{
    hasError?: boolean;
    hasScrollbar?: boolean;
    isSearchable?: boolean;
    isBorderless?: boolean;
}>`
    color: var(--color-primaryDark);
    cursor: ${props => (props.isSearchable ? "text" : "pointer")};
    display: inline-block;
    position: relative;
    width: 100%;

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ hasError }) =>
        hasError &&
        css`
            border-color: var(--color-error);
        `} /* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ hasScrollbar }) =>
        hasScrollbar &&
        css`
            ${StyledMenuList} {
                width: calc(100% - 16px);
            }

            ${StyledOption} {
                margin-right: 16px;
            }
        `} /* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isBorderless }) =>
        isBorderless &&
        css`
            align-items: center;
            display: flex;

            label {
                font-weight: 400;
                margin: 0 8px 0 0;
                font-family: var(--font-primary);
            }

            ${StyledDropdownIndicatorIcon} {
                height: 14px;
                width: 14px;
            }

            ${StyledValueContainer},
            ${StyledControl} {
                height: 24px;
            }

            ${StyledControl} {
                border: 0;
                margin: 0;
                padding: 0;
            }

            ${StyledMenuList} {
                background: var(--color-primaryLight);
                border: 1px solid var(--color-primaryLight);
                border-radius: 5px;
                min-width: 220px;
            }

            ${StyledSingleValue} {
                font-weight: 500;
            }
        `} /* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */
`;
