import styled from "styled-components";

export const StyledFooter = styled.footer`
    align-items: center;
    background: #fff;
    color: #232737;
    display: flex;
    flex-direction: column;
    font-family: var(--font-primary-bold);
    text-align: center;
    width: 100%;
`;

export const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    margin: 24px 0 20px;
    width: 100%;
`;

export const Icons = styled.div`
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 32px;
`;

export const Links = styled.div`
    align-items: center;
    border-top: 1px solid #e8f2f7;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
    line-height: 25px;
    margin-bottom: 32px;
    max-width: 280px;
    padding-top: 32px;
    width: 100%;

    a {
        text-decoration: none;
    }
`;
