import styled from "styled-components";

import { media } from "constants/media";

export const StyledHeader = styled.header`
    max-width: 1920px;
    min-height: 300px;
    padding: 24px;
    position: relative;

    ${media.lgUp} {
        padding: 24px 48px;
    }

    ::before {
        background-image: url("/images/mask.svg");
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: 517px;
        left: -390px;
        position: absolute;
        top: 0;
        width: 566px;

        ${media.lgUp} {
            background-image: url("/images/mask-desktop.svg");
            left: -280px;
            top: -240px;
        }
    }
`;

export const StyledContent = styled.div<{
    success?: boolean;
}>`
    position: relative;
    z-index: 1;

    /* stylelint-disable order/properties-alphabetical-order*/
    ${({ success }) =>
        success &&
        `
            margin-top: 60px;

            &&&{
                p {
                    font-family: var(--font-primary-semibold);
                }
            }
        `}

    p {
        color: var(--color-primaryDark);
        font-size: 16px;
        font-weight: 300;
        line-height: 167%;
        margin: 0 auto 497px;
        max-width: 280px;
        text-align: center;

        ${({ success }) =>
            success &&
            `
            margin-top: 30px;
        `}
    }
    /* stylelint-enable order/properties-alphabetical-order*/
`;

export const StyledImage = styled.div`
    display: flex;
    justify-content: center;
    margin: 8px 0;
    width: 100%;
`;

export const Title = styled.h1`
    color: #feb637;
    font-family: var(--font-primary-bold);
    font-size: 48px;
    line-height: 72px;
    margin: 0 auto;
    text-align: center;
`;
