import { DropdownIndicatorProps } from "react-select";
import React, { FC } from "react";

import { CHEVRON_RIGHT } from "components/shared/icon";
import { DropdownOption } from "components/shared/dropdown/types";
import {
    StyledDropdownIndicator,
    StyledDropdownIndicatorIcon
} from "components/shared/dropdown/Dropdown.styled";

interface Props {
    innerRef: never;
    isFocused: boolean;
    isDisabled: boolean;
}

const DropdownIndicator: FC<
    DropdownIndicatorProps<DropdownOption> & Props
> = props => {
    const { selectProps, innerRef, isDisabled, innerProps } = props;

    return (
        <StyledDropdownIndicator
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={innerRef}
            isIndicatorRotated={selectProps.menuIsOpen}
            {...innerProps}
        >
            <StyledDropdownIndicatorIcon
                icon={CHEVRON_RIGHT}
                size={22}
                viewbox="0 0 24 24"
                color={
                    isDisabled
                        ? "var(--color-secondary-dark)"
                        : "var(--color-primary-dark)"
                }
            />
        </StyledDropdownIndicator>
    );
};

export default DropdownIndicator;
