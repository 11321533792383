/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRouter } from "next/router";
import { useState } from "react";
import Head from "next/head";
import Image from "next/image";
import styled from "styled-components";
import type { NextPage } from "next";

import { Footer } from "components/footer/Footer";
import { Form } from "components/form/Form";
import { Header } from "components/shared/header/Header";
import { INFORMATION } from "components/shared/icon/IconSet";
import { Icon } from "components/shared/icon";
import { Tooltip } from "components/shared/tooltip/Tooltip";
import NotActive from "components/notActive";

const StyledBackground = styled.div<{
    campEnabled: boolean;
}>`
    height: 100%;
    margin: 0 auto;
    max-width: 1920px;
    min-height: 3627px;
    position: relative;
    z-index: 0;

    /* stylelint-disable order/properties-alphabetical-order*/
    ${({ campEnabled }) =>
        campEnabled &&
        `
        min-height: 4283px;
        height: 100%;
    `}/* stylelint-enable order/properties-alphabetical-order*/
`;

const StyledForm = styled.div`
    margin: 60px auto 0;
    position: relative;
    width: 280px;
    z-index: 1;
`;

const CheckboxLabel = styled.label`
    display: flex;
    gap: 10px;
    margin-bottom: 16px;

    span {
        color: #003d80;
        font-family: var(--font-primary-semibold);
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        svg {
            margin-bottom: -6px;
            margin-left: 2px;
        }

        div,
        button {
            display: inline;
        }
    }

    input {
        display: block;
        flex-shrink: 0;
        height: 25px;
        margin-top: 10px;
        max-height: 25px;
        max-width: 25px;
        width: 25px;
    }
`;

const SendButton = styled.button`
    align-items: center;
    align-self: stretch;

    /* stylelint-disable function-parentheses-space-inside */
    background: linear-gradient(
        90deg,
        rgba(239, 170, 21, 1) 0.36%,
        rgba(244, 130, 1, 1) 99.6%
    );
    /* stylelint-enable function-parentheses-space-inside */

    border: 0;
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-family: var(--font-primary-semibold);
    font-size: 21px;
    justify-content: center;
    line-height: 167%;
    margin: 40px 0;
    padding: 16px;
    width: 100%;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    /* stylelint-disable order/order*/
    /* stylelint-disable order/properties-alphabetical-order*/
    ${({ disabled }) =>
        disabled &&
        `
            opacity: 0.5;
            cursor: not-allowed;

            &:hover {
                cursor: not-allowed;
                opacity: 0.5;
            }
        `}/* stylelint-enable order/properties-alphabetical-order*/ /* stylelint-enable order/order*/
`;

const EmailFieldLabel = styled.div`
    color: var(--color-secondary);
    font-family: var(--font-primary-bold);
    font-size: 24px;
    line-height: 40px;
    margin-top: 40px;
`;

const EmailField = styled.input<{ formFeedback: string | null }>`
    border: 0;
    border-radius: 8px;
    color: var(--color-secondary);
    font-family: var(--font-primary-semibold);
    font-size: 16px;
    margin: 8px 0;
    padding: 16px;
    width: 100%;

    ::placeholder {
        font-family: var(--font-primary);
    }

    /* stylelint-disable order/order*/
    /* stylelint-disable order/properties-alphabetical-order*/
    ${({ formFeedback }) =>
        formFeedback &&
        `
        border: 1px solid #F86F0B;
    `}/* stylelint-enable order/properties-alphabetical-order*/ /* stylelint-enable order/order*/
`;

const FormFeedback = styled.div`
    align-items: flex-start;
    color: #f86f0b;
    display: flex;
    font-family: var(--font-primary-bold);
    gap: 10px;
    margin: 16px 0;

    img {
        flex-shrink: 0;
        min-height: 24px;
    }

    span {
        min-width: 24px;
    }
`;

const Home: NextPage = () => {
    const router = useRouter();
    // eslint-disable-next-line
    // @ts-ignore
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [departureDate, setDepartureDate] = useState("");
    const [type, setType] = useState("");
    // eslint-disable-next-line
    // @ts-ignore
    const [campType, setCampType] = useState("");
    const [allowANWBNewsletter, setAllowANWBNewsletter] = useState(false);
    // eslint-disable-next-line
    // @ts-ignore
    const [formFeedback, setFormFeedback] = useState<string | null>(null);

    async function sendForm() {
        setLoading(true);
        setFormFeedback(null);

        const emailReg = new RegExp(
            /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        );
        if (!emailReg.test(email)) {
            setLoading(false);
            setFormFeedback("Ongeldig e-mailadres");
            return;
        }

        try {
            const requestOptions = {
                body: JSON.stringify({
                    allowANWBNewsletter,
                    allowCoronaUpdates: false,
                    campType,
                    country,
                    departureDate,
                    email,
                    type
                }),
                method: "POST"
            };
            const response = await fetch("/api/signup", requestOptions).then(
                response => response.json()
            );

            if (!response?.success) {
                setFormFeedback(response.message);
                setLoading(false);
            } else {
                router.push("/success");
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }

    const formDisabled =
        email === "" || country === "" || type === "" || departureDate === "";

    function scrollTo() {
        router.push("/#land");
    }

    const isActive = process.env.CAMPAIGN_ACTIVE === "true";

    return (
        <>
            {!isActive && <NotActive />}
            <StyledBackground campEnabled={type === "Kampeervakantie"}>
                {type === "Kampeervakantie" ? (
                    <Image
                        src="/images/background_camp.png"
                        layout="fill"
                        objectFit="cover"
                        priority={true}
                    />
                ) : (
                    <Image
                        src="/images/background_nocamp.png"
                        layout="fill"
                        objectFit="cover"
                        priority={true}
                    />
                )}
                <div>
                    <Head>
                        <title>Fijne Vakantie Check - ANWB</title>
                        <meta
                            name="description"
                            content="Vertel ons waar je naartoe gaat op vakantie en wanneer je vertrekt. Dan ontvang je maximaal 5 e-mails met handige tips, toegespitst op jouw vakantiebestemming. Handig toch?"
                        />
                        <link rel="icon" href="/favicon.ico" />
                    </Head>

                    <Header />

                    <Form
                        type={type}
                        setCountry={value => setCountry(value)}
                        country={country}
                        setDepartureDate={value => setDepartureDate(value)}
                        setType={value => setType(value)}
                        setCampType={value => setCampType(value)}
                    />

                    <StyledForm
                        id="checkboxes"
                        style={{ position: "relative" }}
                    >
                        <CheckboxLabel>
                            <input
                                type="checkbox"
                                name="newsletter"
                                checked={allowANWBNewsletter}
                                onChange={e =>
                                    setAllowANWBNewsletter(e.target.checked)
                                }
                            />
                            <span>
                                Wil je de nieuwsbrief ANWB Vakantie ontvangen?
                                <Tooltip tooltipText="2x per week alles voor een leuke en zorgeloze vakantie: inspiratie, praktische informatie en de leukste reisaanbiedingen met ledenvoordeel in je inbox.">
                                    <Icon icon={INFORMATION} size={24} />
                                </Tooltip>
                            </span>
                        </CheckboxLabel>

                        <EmailFieldLabel>E-mailadres</EmailFieldLabel>

                        {formFeedback && (
                            <FormFeedback>
                                <Image
                                    src="/images/warning.svg"
                                    width={24}
                                    height={24}
                                />
                                <span>{formFeedback}</span>
                            </FormFeedback>
                        )}

                        <EmailField
                            type="email"
                            placeholder="voorbeeld@voorbeeld.nl"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            formFeedback={formFeedback}
                        />
                        <div style={{ position: "relative" }}>
                            {formDisabled && (
                                /* eslint-disable */
                                <div
                                    onClick={() => {
                                        scrollTo();
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        height: 68,
                                        position: "absolute",
                                        width: "100%",
                                        zIndex: 1
                                    }}
                                />
                                /* eslint-enable */
                            )}
                            {isActive && (
                                <SendButton
                                    onClick={() => !loading && sendForm()}
                                    disabled={formDisabled || loading}
                                >
                                    Schrijf je in
                                </SendButton>
                            )}
                        </div>
                    </StyledForm>
                </div>
            </StyledBackground>
            <Footer />
        </>
    );
};

export default Home;
