import { SingleValueProps } from "react-select";
import React, { FC } from "react";

import { DropdownOption } from "components/shared/dropdown/types";
import {
    StyledOptionIcon,
    StyledSingleValue
} from "components/shared/dropdown/Dropdown.styled";

const SingleValue: FC<SingleValueProps<DropdownOption>> = ({
    data: { label, chipLabel, icon },
    innerProps
}) => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <StyledSingleValue {...innerProps}>
            {icon && <StyledOptionIcon>{icon}</StyledOptionIcon>}

            {chipLabel ?? label}
        </StyledSingleValue>
    );
};

export default SingleValue;
