import React, { FC } from "react";

import { Message } from "components/shared/ErrorMessage/ErrorMessage.styled";

interface Props {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    message?: string;
}

const ErrorMessage: FC<Props> = ({ className, message }) => (
    <Message className={className}>{message}</Message>
);

export default ErrorMessage;
