import { ControlProps } from "react-select";
import React, { FC } from "react";

import { DropdownOption } from "components/shared/dropdown/types";
import { StyledControl } from "components/shared/dropdown/Dropdown.styled";

const Control: FC<ControlProps<DropdownOption>> = props => {
    const { children, innerRef, isFocused, isDisabled, innerProps } = props;

    return (
        <StyledControl
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={innerRef}
            {...innerProps}
            isDisabled={isDisabled}
            isFocused={isFocused}
        >
            {children}
        </StyledControl>
    );
};

export default Control;
