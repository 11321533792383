import styled from "styled-components";

export const StyledContainer = styled.div`
    background: var(--color-white);
    border-radius: 8px;
    margin: 0 auto;
    max-width: 272px;
    padding: 16px;
`;

export const StyledSpacer = styled.span`
    display: block;
    margin: 590px 0 0;
`;

export const StyledTitle = styled.p`
    align-items: center;
    color: var(--color-primaryDark);
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;

    span {
        color: var(--color-primary);
        font-size: 12px;
        font-weight: 400;
        line-height: 32px;
        margin-left: 8px;
    }
`;
