import { useRouter } from "next/router";

import { CampSelector } from "components/form/partials/campSelector/CampSelector";
import { DateSelector } from "components/form/partials/dateSelector/DateSelector";
import { DropdownOption } from "components/shared/dropdown/types";
import { TravelSelector } from "components/form/partials/travelSelector/TravelSelector";
import CountryDropdown from "components/shared/countryDropdown/CountryDropdown";

type Props = {
    type: string;
    setCountry: (value: string) => unknown;
    country: string;
    setType: (value: string) => void;
    setCampType: (value: string) => void;
    setDepartureDate: (value: string) => void;
};

export function Form({
    type,
    setCountry,
    country,
    setDepartureDate,
    setType,
    setCampType
}: Props): JSX.Element {
    const router = useRouter();
    const countries = [
        { code: "NL", name: "Nederland" },
        { code: "BE", name: "België" },
        { code: "DK", name: "Denemarken" },
        { code: "DE", name: "Duitsland" },
        { code: "FR", name: "Frankrijk" },
        { code: "GR", name: "Griekenland" },
        { code: "GB", name: "Groot-Brittannië" },
        { code: "IT", name: "Italië" },
        { code: "HR", name: "Kroatië" },
        { code: "LU", name: "Luxemburg" },
        { code: "NO", name: "Noorwegen" },
        { code: "AT", name: "Oostenrijk" },
        { code: "PT", name: "Portugal" },
        { code: "SI", name: "Slovenië" },
        { code: "ES", name: "Spanje" },
        { code: "CZ", name: "Tsjechië" },
        { code: "TR", name: "Turkije" },
        { code: "SE", name: "Zweden" },
        { code: "CH", name: "Zwitserland" },
        { code: "EU", name: "Anders, binnen Europa" },
        { code: "OTHER", name: "Anders, buiten Europa" }
    ];

    function getValue(value: string): string {
        if (value === "Anders, binnen Europa") return "Anders_binnen_Europa";
        if (value === "Anders, buiten Europa") return "Anders_buiten_Europa";
        return value;
    }

    return (
        <>
            <div id="land" style={{ position: "relative", zIndex: 1 }}>
                <CountryDropdown
                    label="Naar welk land ga je?"
                    countries={countries}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={(value: DropdownOption) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setCountry(getValue(value?.label) as string) || "";
                        router.push("/#datum");
                    }}
                />
            </div>

            <div id="datum" style={{ position: "relative", zIndex: 1 }}>
                <DateSelector
                    onChange={value => {
                        setDepartureDate(value);
                    }}
                />
            </div>
            <div id="reis" style={{ position: "relative", zIndex: 1 }}>
                <TravelSelector
                    country={country}
                    onChange={value => {
                        setType(value);
                    }}
                />
            </div>
            <div
                id="kampeervakantie"
                style={{ position: "relative", zIndex: 1 }}
            >
                {type === "Kampeervakantie" && (
                    <CampSelector
                        onChange={value => {
                            setCampType(value);
                        }}
                    />
                )}
            </div>
        </>
    );
}
