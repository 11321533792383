import { useRouter } from "next/router";
import DatePicker, { registerLocale } from "react-datepicker";
import React, { useState } from "react";
import nl from "date-fns/locale/nl";

registerLocale("nl", nl);

import {
    StyledContainer,
    StyledSpacer,
    StyledTitle
} from "components/form/partials/dateSelector/DateSelector.styled";
import InputLabel from "components/shared/inputLabel/InputLabel";

type Props = {
    onChange: (date: string) => void;
};

export function DateSelector({ onChange }: Props): JSX.Element {
    const router = useRouter();
    const [startDate, setStartDate] = useState<Date | null>(null);

    function handleOnChange(date: Date) {
        setStartDate(date);

        // Format selectedDate to yyyy-mm-dd
        const selectedDate = new Date(date);
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth() + 1;
        const day = selectedDate.getDate();
        const formattedDate = `${year}-${month}-${day}`;

        onChange(formattedDate);
    }

    const intervalEndDate = new Date(process.env.CALENDAR_END_DATE!);
    const intervalStartDate =
        new Date().getTime() <
        new Date(process.env.CALENDAR_START_DATE!).getTime()
            ? new Date(process.env.CALENDAR_START_DATE!)
            : new Date();

    // Get local date string for end date and month name
    const endDateString = intervalEndDate.toLocaleDateString("nl-NL", {
        day: "numeric",
        month: "short"
    });

    return (
        <>
            <StyledSpacer />
            <InputLabel value="Wanneer ga je weg?"></InputLabel>
            <StyledContainer>
                <StyledTitle>
                    Vertrekdatum <span>uiterlijk {endDateString}</span>
                </StyledTitle>

                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore*/}
                <DatePicker
                    placeholderText="Kies een datum"
                    includeDateIntervals={[
                        {
                            end: intervalEndDate,
                            start: intervalStartDate
                        }
                    ]}
                    dateFormat="dd-MMM-yyyy"
                    locale="nl"
                    selected={startDate || intervalStartDate}
                    onChange={(date: Date) => {
                        handleOnChange(date);
                        router.push("/#reis");
                    }}
                />
            </StyledContainer>
        </>
    );
}
