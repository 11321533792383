import { ControlProps } from "react-select";
import React, { FC } from "react";

import { DropdownOption } from "components/shared/dropdown/types";
import CustomControl from "components/shared/dropdown/CustomControl";

const CountryControl: FC<ControlProps<DropdownOption>> = props => (
    <div>
        <CustomControl {...props} />
    </div>
);

export default CountryControl;
