import React, { FC } from "react";

import { Icon } from "components/shared/icon";
import {
    Label,
    StyledContainer
} from "components/shared/inputLabel/InputLabel.styled";
import { MARKER } from "components/shared/icon/IconSet";

interface Props {
    htmlFor?: string;
    value: string;
}

const InputLabel: FC<Props> = ({ htmlFor, value, ...props }) => {
    return (
        <StyledContainer>
            <Icon icon={MARKER} size={53} viewbox="0 0 37 53" />
            <Label htmlFor={htmlFor} {...props}>
                {value}
            </Label>
        </StyledContainer>
    );
};

export default InputLabel;
