import { OptionProps } from "react-select";
import React, { FC } from "react";

import { DropdownOption } from "components/shared/dropdown/types";
import {
    StyledOption,
    StyledOptionIcon
} from "components/shared/dropdown/Dropdown.styled";

const Option: FC<OptionProps<DropdownOption>> = ({
    data: { label, icon },
    isDisabled,
    isSelected,
    isFocused,
    innerRef,
    innerProps
}) => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <StyledOption
            ref={innerRef}
            isDisabled={isDisabled}
            hasIcon={icon}
            isSelected={isSelected || isFocused}
            {...innerProps}
        >
            {icon && <StyledOptionIcon>{icon}</StyledOptionIcon>}

            {label}
        </StyledOption>
    );
};

export default Option;
