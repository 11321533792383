import { useRouter } from "next/router";
import Image from "next/image";
import React, { useState } from "react";

import { StyledContainer } from "components/form/partials/dateSelector/DateSelector.styled";
import {
    StyledImage,
    StyledOption,
    StyledSpacer
} from "components/form/partials/travelSelector/TravelSelector.styled";
import InputLabel from "components/shared/inputLabel/InputLabel";

type Props = {
    country: string;
    onChange: (value: string) => void;
};

export function TravelSelector({ onChange, country }: Props): JSX.Element {
    const [selectedOption, setSelectedOption] = useState("");
    const router = useRouter();
    const travelOptions = [
        {
            icon: "/images/auto.svg",
            name: "Autovakantie",
            value: "Autovakantie"
        },
        {
            icon: "/images/caravan.svg",
            name: "Kampeervakantie",
            value: "Kampeervakantie"
        },
        {
            icon: "/images/vliegtuig.svg",
            name: "Vliegvakantie",
            value: "Vliegvakantie"
        }
    ];

    function getTravelOptionByCountry() {
        if (["Nederland", "België", "Luxemburg"].includes(country || "")) {
            return travelOptions.filter(o => o.name !== "Vliegvakantie");
        } else {
            return travelOptions;
        }
    }

    function handleOnChange(value: string) {
        setSelectedOption(value);
        onChange(value);
        const section =
            value === "Kampeervakantie" ? "kampeervakantie" : "checkboxes";
        router.push(`/#${section}`);
    }

    return (
        <>
            <StyledSpacer />
            <InputLabel value="Wat voor vakantie wordt het?"></InputLabel>
            <StyledContainer>
                {getTravelOptionByCountry().map(option => (
                    <>
                        <StyledOption
                            selected={selectedOption === option.value}
                            onClick={() => {
                                handleOnChange(option.value);
                            }}
                        >
                            <Image
                                src={option.icon}
                                width={42}
                                height={22}
                                alt={option.value}
                            />
                            <span>{option.name}</span>
                        </StyledOption>
                    </>
                ))}
            </StyledContainer>
            {selectedOption === "" && (
                <StyledImage>
                    <Image src="/images/auto.png" width={280} height={280} />
                </StyledImage>
            )}
            {selectedOption === "Autovakantie" && (
                <StyledImage>
                    <Image src="/images/auto.png" width={280} height={280} />
                </StyledImage>
            )}
            {selectedOption === "Kampeervakantie" && (
                <StyledImage>
                    <Image src="/images/caravan.png" width={280} height={280} />
                </StyledImage>
            )}
            {selectedOption === "Vliegvakantie" && (
                <StyledImage>
                    <Image
                        src="/images/vliegtuig.png"
                        width={280}
                        height={280}
                    />
                </StyledImage>
            )}
        </>
    );
}
