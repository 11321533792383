/* eslint-disable jsx-a11y/anchor-is-valid */
import Image from "next/image";
import Link from "next/link";

import {
    Icons,
    Links,
    StyledFooter,
    Title
} from "components/footer/Footer.styled";

export function Footer(): JSX.Element {
    return (
        <StyledFooter>
            <Title>Volg ANWB</Title>
            <Icons>
                <Link href="https://www.facebook.com/ANWB" passHref>
                    <a href="#">
                        <Image src="/facebook.png" width="40" height="40" />
                    </a>
                </Link>
                <Link href="https://www.linkedin.com/company/anwb/" passHref>
                    <a href="#">
                        <Image src="/linkedin.png" width="40" height="40" />
                    </a>
                </Link>
                <Link href="https://www.youtube.com/anwb" passHref>
                    <a href="#">
                        <Image src="/youtube.png" width="40" height="40" />
                    </a>
                </Link>
                <Link href="https://twitter.com/anwb" passHref>
                    <a href="#">
                        <Image src="/twitter.png" width="40" height="40" />
                    </a>
                </Link>
                <Link href="https://www.instagram.com/anwb/" passHref>
                    <a href="#">
                        <Image src="/instagram.png" width="40" height="40" />
                    </a>
                </Link>
            </Icons>
            <Links>
                <Link href="https://www.anwb.nl/" passHref>
                    <a href="#">ANWB.nl</a>
                </Link>
                <Link href="https://www.anwb.nl/jouw-privacy" passHref>
                    <a href="#">Privacy</a>
                </Link>
                <Link href="https://www.anwb.nl/contact-en-service" passHref>
                    <a href="#">Contact</a>
                </Link>
            </Links>
        </StyledFooter>
    );
}
