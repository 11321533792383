import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import Select, { SelectComponentsConfig, StylesConfig } from "react-select";

import {
    DropdownOption,
    OnChangeAction,
    OnChangeValue
} from "components/shared/dropdown/types";
import {
    StyledDropdown,
    StyledInput
} from "components/shared/dropdown/Dropdown.styled";
import Control from "components/shared/dropdown/CustomControl";
import DropdownIndicator from "components/shared/dropdown/CustomDropdownIndicator";
import ErrorMessage from "components/shared/ErrorMessage/ErrorMessage";
import InputLabel from "components/shared/inputLabel/InputLabel";
import MenuList from "components/shared/dropdown/CustomMenuList";
import MultiValueLabel from "components/shared/dropdown/CustomMultiValueLabel";
import Option from "components/shared/dropdown/CustomOption";
import Placeholder from "components/shared/dropdown/CustomPlaceholder";
import SingleValue from "components/shared/dropdown/CustomSingleValue";
import ValueContainer from "components/shared/dropdown/CustomValueContainer";

interface DropdownProps {
    /**
     * Name of the HTML Input (optional - without this, no input will be rendered)
     */
    name?: string;

    /**
     * Array of options that populate the select menu
     */
    options?: Array<DropdownOption>;

    /**
     * Disables the dropdown
     */
    disabled?: boolean;

    /**
     * Enables the user to type in the input and filter the options
     */
    isSearchable?: boolean;

    /**
     * Specifies the default value of the dropdown
     */
    defaultValue?: DropdownOption;

    /**
     * Callback function fired when the user selects an option from list
     */
    onChange?: (value: OnChangeValue, { action }: OnChangeAction) => void;

    /**
     * Custom method to filter whether an option should be displayed in the menu
     */
    filterOption?: (params: DropdownOption | string) => boolean;

    /**
     * Hide the selected option from the menu
     */
    hideSelectedOptions?: boolean;

    /**
     * Is the select value clearable
     */
    isClearable?: boolean;

    /**
     * Support multiple selected options
     */
    isMulti?: boolean;

    /**
     * Whether the menu is open
     */
    menuIsOpen?: boolean;

    /**
     * Placeholder for the select value
     */
    placeholder?: string;

    /**
     * Tabindex for the select component
     */
    tabIndex?: number;

    /**
     * Customize input to error status
     */
    hasError?: boolean;

    /**
     * Message the be shown when the input is not valid
     */
    errorMessage?: string;

    /**
     * Label of the dropdown
     */
    label?: string;

    /**
     * Makes the select required
     */
    required?: boolean;

    /**
     * This complex object includes all the compositional components that are used
     * in `react-select`. If you wish to overwrite a component, pass in an object
     * with the appropriate namespace.
     *
     * If you only wish to restyle a component, we recommend using the `styles` prop
     * instead. For a list of the components that can be passed in, and the shape
     * that will be passed to them, see [the components docs](/api#components)
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    components?: SelectComponentsConfig<DropdownOption>;

    /**
     * Style modifier methods
     */
    customStyles?: StylesConfig;

    /**
     * The value of the select; reflected by the selected option
     */
    value?: DropdownOption;

    /**
     * Wheter the dropdown has minimal styling or not
     */
    isBorderless?: boolean;

    /**
     * Indicates whether is loading more data
     */
    isLoading?: boolean;

    /**
     * Message to be shown when loading more data
     */
    loadingMessage?: string;

    /**
     * Fires whenever the list is scrolled until the last option
     */
    onScrollToBottom?: (event: WheelEvent | TouchEvent) => void;
    /**
     * Indicates if it's a bank dropdown.
     */
    isBankDropdown?: boolean;
}

export const Dropdown = forwardRef(
    (
        {
            options,
            name,
            label,
            placeholder,
            tabIndex = 0,
            hideSelectedOptions = false,
            isSearchable = false,
            isClearable = false,
            disabled,
            defaultValue,
            onChange,
            filterOption,
            hasError,
            errorMessage,
            required,
            components,
            customStyles,
            value,
            isBorderless,
            isLoading,
            loadingMessage,
            ...props
        }: DropdownProps,
        ref: ForwardedRef<HTMLInputElement>
    ): JSX.Element => {
        const [inputValue, setInputValue] = useState<string | number>(
            value?.value || ""
        );

        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore*/
        const handleChange = (value, action) => {
            setInputValue(value);

            if (onChange) {
                onChange(value, action);
            }
        };

        useEffect(() => {
            setInputValue(value?.value || "");
        }, [value]);

        return (
            <StyledDropdown
                hasError={hasError}
                hasScrollbar={options && options.length > 8}
                isSearchable={isSearchable}
                isBorderless={isBorderless}
            >
                {label && (
                    <InputLabel
                        htmlFor={name}
                        value={label}
                        data-test-id="label"
                    />
                )}

                {/* TODO: Figure out why this is giving typescript errors */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore*/}
                <Select
                    {...props}
                    isLoading={isLoading}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ref={ref}
                    name={name}
                    tabIndex={tabIndex}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    blurInputOnSelect
                    hideSelectedOptions={hideSelectedOptions}
                    isSearchable={isSearchable}
                    isDisabled={disabled}
                    isClearable={isClearable}
                    options={options}
                    onChange={handleChange}
                    filterOption={filterOption}
                    value={value}
                    focus
                    components={{
                        Control,
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                        LoadingIndicator: () => null,
                        MenuList,
                        MultiValueLabel,
                        Option,
                        Placeholder,
                        SingleValue,
                        ValueContainer,
                        ...components
                    }}
                    styles={customStyles}
                    loadingMessage={() => loadingMessage}
                />

                {required && (
                    <StyledInput
                        tabIndex={-1}
                        autoComplete="off"
                        value={inputValue}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onFocus={() => ref.current.focus()}
                        required={required}
                    />
                )}
                {hasError && <ErrorMessage message={errorMessage} />}
            </StyledDropdown>
        );
    }
);

Dropdown.displayName = "Dropdown";
