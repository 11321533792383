import styled, { css } from "styled-components";

export const StyledOption = styled.div<{ selected: boolean }>`
    align-items: center;
    color: var(--color-primaryDark);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    margin: 8px 0;

    span {
        margin-left: 8px;
    }

    &:last-child {
        line-height: 22px;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ selected }) =>
        selected &&
        css`
            //background: purple;
            color: var(--color-primary);

            img {
                filter: brightness(3);
            }
        `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;

export const StyledImage = styled.div`
    margin: 32px auto;
    width: 280px;
`;

export const StyledSpacer = styled.span`
    display: block;
    margin: 64px 0 0;
`;
