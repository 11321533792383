import { ValueContainerProps } from "react-select";
import React, { FC } from "react";

import { DropdownOption as Options } from "components/shared/dropdown/types";
import { StyledValueContainer } from "components/shared/dropdown/Dropdown.styled";
interface Props {
    innerRef: never;
    innerProps: never;
    isDisabled: boolean;
}

const ValueContainer: FC<ValueContainerProps<Options> & Props> = props => {
    const { children, isDisabled, innerRef, innerProps } = props;

    return (
        <StyledValueContainer
            ref={innerRef}
            {...innerProps}
            isDisabled={isDisabled}
        >
            {children}
        </StyledValueContainer>
    );
};

export default ValueContainer;
