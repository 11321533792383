import React, { FC } from "react";
import ReactCountryFlag from "react-country-flag";

import { Dropdown } from "components/shared/dropdown/Dropdown";
import {
    DropdownOption,
    OnChangeAction,
    OnChangeValue
} from "components/shared/dropdown/types";
import { StyledCountryDropdown } from "components/shared/countryDropdown/CountryDropdown.styled";
import CountryControl from "components/shared/countryDropdown/CountryControl";

export type Country = {
    /**
     * Country name, shown as option when the dropdown is expanded
     */
    name: string;

    /**
     * ISO 3166-1 alpha-2 code of the country, used to determine what flag and phone prefix are used
     */
    code: string;
};

interface CountryDropdownProps {
    /**
     * Label of the dropdown
     */
    label?: string;

    /**
     * List of countries to be shown in the dropdown
     */
    countries: Array<Country>;

    /**
     * Whether the dropdown is disabled
     */
    disabled?: boolean;

    /**
     * Country that should be selected by default
     */
    defaultCountry?: Country;

    /**
     * Function fired when a country is selected
     */
    onChange: (value: OnChangeValue, { action }: OnChangeAction) => void;

    /**
     * Use phone prefixes (dial codes) instead of Country code as value
     */
    usePhonePrefix?: boolean;
}

const mapCountryToOption = ({ name, code }: Country): DropdownOption => {
    return {
        chipLabel: name,
        icon:
            code !== "OTHER" ? (
                <ReactCountryFlag
                    countryCode={code}
                    style={{
                        borderRadius: "12px",
                        height: "32px",
                        width: "32px"
                    }}
                    svg
                />
            ) : (
                <span aria-label="world" role="img" style={{ fontSize: 28 }}>
                    🌍
                </span>
            ),
        label: name,
        value: code
    };
};

const CountryDropdown: FC<CountryDropdownProps> = props => {
    const { label, countries, disabled, onChange } = props;

    const options = countries.map(country => mapCountryToOption(country));

    return (
        <StyledCountryDropdown>
            <Dropdown
                label={label}
                disabled={disabled}
                options={options}
                placeholder="Kies een land"
                customStyles={{
                    menu: provided => ({
                        ...provided,
                        zIndex: 10
                    })
                }}
                components={{
                    Control: CountryControl
                }}
                onChange={onChange}
            />
        </StyledCountryDropdown>
    );
};

export default CountryDropdown;
