import Image from "next/image";

import {
    StyledContent,
    StyledHeader,
    StyledImage,
    Title
} from "components/shared/header/Header.styled";

type Props = {
    success?: boolean;
};

export function Header({ success }: Props): JSX.Element {
    return (
        <StyledHeader>
            <Image
                src="/images/anwb-logo.svg"
                priority
                unoptimized
                width={84}
                height={40}
            />
            <StyledContent success={success}>
                {success ? (
                    <>
                        <StyledImage>
                            <Image
                                src="/images/thanks_icon.svg"
                                width={90}
                                height={45}
                                priority
                                unoptimized
                            />
                        </StyledImage>
                        <Title>Bedankt!!</Title>
                        <p>
                            We hebben je aanmelding voor de Fijne Vakantie Check
                            ontvangen. Je ontvangt maximaal 5 e-mails ter
                            voorbereiding op je vakantie. We wensen je alvast
                            een goede reis!
                        </p>
                    </>
                ) : (
                    <>
                        <StyledImage>
                            <Image
                                src="/images/fijne-vakantie.png"
                                width={271}
                                height={221}
                                priority
                                unoptimized
                            />
                        </StyledImage>
                        <p>
                            Vertel ons waar je naartoe gaat op vakantie en
                            wanneer je vertrekt. Dan ontvang je maximaal 5
                            e-mails met handige tips, toegespitst op jouw
                            vakantiebestemming. Handig toch?
                        </p>
                    </>
                )}
            </StyledContent>
        </StyledHeader>
    );
}
