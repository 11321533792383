import { PlaceholderProps } from "react-select";
import React, { FC } from "react";

import { DropdownOption } from "components/shared/dropdown/types";
import { StyledPlaceholder } from "components/shared/dropdown/Dropdown.styled";

interface Props {
    innerRef: never;
}

const Placeholder: FC<PlaceholderProps<DropdownOption> & Props> = props => {
    const { children, innerRef, innerProps } = props;

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <StyledPlaceholder ref={innerRef} {...innerProps}>
            {children}
        </StyledPlaceholder>
    );
};

export default Placeholder;
