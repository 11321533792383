import styled from "styled-components";

export const StyledContainer = styled.div`
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 250px;

    svg {
        margin-right: 16px;
        margin-top: -16px;
        min-width: 37px;
    }
`;

export const Label = styled.label`
    color: var(--color-primaryDark);
    display: block;
    font-family: var(--font-primary-bold), sans-serif;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 16px;
    user-select: none;
`;
